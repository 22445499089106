import React, { useContext, useEffect, useState } from "react";
import dynamic from "next/dynamic";

//Context
import { AppContext, ServiceRequestType } from "@vahak/core/dist/app-context";
import { useRouter } from "next/router";
import { getAllVwoABTestVariations } from "../methods/getABTestVariation";
import { AB_TEST_CAMPAIGN_IDS } from "../constants/abTestVariations";
import SignupMandatePopup from "./user-profile/mandate-user-verification/app-mandate/SignupMandatePopup";
import ServiceRequestModal from "./service-request-modal/ServiceRequestModal";
import { UserAction } from "@vahak/core/dist/constants";
import useValidateUserStatus from "../hooks/useValidateUserStatus";
import LoginModalGlobalWrapper from "./LoginModalGlobalWrapper";
import { moEngageUserAttributeTrack } from "@vahak/core/dist/_services/user-analytics.service";
import { WebsiteUserType } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import ClearDueModal from "./clear-due-modal/ClearDueModal";

//Components
const AadhaarAndPanVerification = dynamic(
    () => import("./user-profile/mandate-user-verification/aadhaar-and-pan-verification/AadhaarAndPanVerification"),
    { ssr: false }
);
const GSTVerification = dynamic(
    () => import("./user-profile/mandate-user-verification/gst-verification/GstVerification"),
    { ssr: false }
);
const UpgradeMembershipPlanModal = dynamic(
    () => import("./user-profile/mandate-user-verification/upgrade-membership-plan/UpgradeMembershipPlan"),
    { ssr: false }
);
const AppRedirectionPopup = dynamic(
    () => import("./user-profile/mandate-user-verification/app-redirection/AppRedirection"),
    { ssr: false }
);
const ChangeLanguage = dynamic(() => import("./transporter/language/ChangeLanguage"), {
    ssr: false
});
const AddBankPage = dynamic(() => import("./bank/add-bank-page/AddBankPage"), {
    ssr: false
});
const ShareModal = dynamic(() => import("./marketplace/common/share-modal/ShareModal"), {
    ssr: false
});
const AppMandatePopup = dynamic(
    () => import("../components/user-profile/mandate-user-verification/app-mandate/AppMandatePopup"),
    {
        ssr: false
    }
);

/**
 * This component is just a wrapper for all the components which is imported globally
 * All type of conditional renders can be handled inside this component itself
 * To reduce loading of unused components when DOM loading
 *
 * @returns Components
 */

export interface GlobalComponentsWrapperProps {
    pageRoute?: string;
}

const GlobalComponentsWrapper = ({ pageRoute }: GlobalComponentsWrapperProps) => {
    const {
        showGstVerificationPopup,
        showMandateKycPopup,
        showUpgradeMembershipPlanPopup,
        showBankAddPopup,
        setShowBankAddPopup,
        onSuccessAddBankCallback,
        showAppRedirectionPopup,
        showShareModalPopup,
        setShowShareModalPopup,
        updateRoute,
        isPhoneVerified,
        setServiceRequestType,
        serviceRequestType,
        serviceType,
        showClearDuePopup,
        //
        setShowMandateKycPopup,
        setShowGstVerificationPopup,
        setShowUpgradeMembershipPlanPopup,
        mandateUserVerificationCallBackFunction,
        setMandateUserVerificationCallBackFunction,
        setMandateUserVerificationActionTriggered,
        mandateUserVerificationActionTriggered,
        setMandateUserVerificationActionType,
        mandateUserVerificationActionType,
        companyId,
        customerCompanyId,
        ...restContext
    } = useContext(AppContext);

    let showAppMandatePopup = false;
    let showSignUpMandatePopup = false;
    const [closeAppMandatePopup, setCloseAppMandatePopup] = useState(false);

    const { abTestVariations }: any = getAllVwoABTestVariations();

    /**
     * Temp condition check
     * This will be removed when login modal is refactored to use context or local state
     */
    const router = useRouter();
    const isLoginModalOpen = router.asPath.split("#")[1]?.includes("Login");
    const isGPSModalOpen = router.asPath.split("#")[1]?.includes("gps");
    const isVerificationModalOpen = router.asPath.split("#")[1]?.includes("verify-id");
    if (!serviceRequestType) {
        isGPSModalOpen && setServiceRequestType?.(ServiceRequestType.GPS);
    }
    if (!showMandateKycPopup) {
        isVerificationModalOpen && setShowMandateKycPopup?.(true);
    }
    if (typeof window != "undefined" && window) {
        window.VWO = window.VWO || [];
        window.VWO.event =
            window.VWO.event ||
            function () {
                window.VWO.push(["event"].concat([].slice.call(arguments)));
            };

        const is_iOS = window?.navigator.platform.indexOf("Mac") === 0 || window?.navigator.platform === "iPhone";
        const ENV = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_REACT_APP_ENV;
        const downloadAppTestCampaign =
            ENV === "production" ? AB_TEST_CAMPAIGN_IDS["downloadAppProd"] : AB_TEST_CAMPAIGN_IDS["downloadApp"];

        if (!isPhoneVerified) {
            if (abTestVariations[downloadAppTestCampaign] == 2 && !showAppMandatePopup) {
                window?.VWO?.event("mandate_download_app_shown");
                showSignUpMandatePopup = false;
                showAppMandatePopup = true;
            } else if (
                abTestVariations[downloadAppTestCampaign] == 3 &&
                !isLoginModalOpen &&
                serviceType &&
                !(companyId && isPhoneVerified)
            ) {
                window?.VWO?.event("mandate_signup_shown");
                showAppMandatePopup = false;
                //showSignUpMandatePopup = true;
            }
        }
    }

    useEffect(() => {
        if (router.isReady) {
            const ENV = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_REACT_APP_ENV;
            const downloadAppTestCampaign =
                ENV === "production" ? AB_TEST_CAMPAIGN_IDS["downloadAppProd"] : AB_TEST_CAMPAIGN_IDS["downloadApp"];
            if (
                abTestVariations[downloadAppTestCampaign] == 3 &&
                !isLoginModalOpen &&
                !isPhoneVerified &&
                serviceType
            ) {
                router?.push({ hash: "#Login" });
            }
        }
    }, [serviceType, isPhoneVerified, abTestVariations, router.isReady]);

    const onCloseBtnClick = () => {
        setCloseAppMandatePopup(true);
    };

    // const showLangMenu = router.asPath.includes("transporters");

    const handleBankPopupToggle = () => setShowBankAddPopup?.(false);

    useEffect(() => {
        if (updateRoute) {
            updateRoute?.(pageRoute);
            return updateRoute;
        }
    }, [updateRoute, pageRoute]);

    const { ValidateLoadBidding, ValidateLoadPosting, ValidateLorryBidding, ValidateLorryPosting } =
        useValidateUserStatus();

    useEffect(() => {
        if (mandateUserVerificationActionType && companyId && isPhoneVerified && mandateUserVerificationActionTriggered)
            switch (mandateUserVerificationActionType) {
                case UserAction.LOAD_BID:
                    return ValidateLoadBidding(() => {
                        mandateUserVerificationCallBackFunction?.();
                        setMandateUserVerificationActionType?.("");
                        setMandateUserVerificationActionTriggered?.(false);
                        setMandateUserVerificationCallBackFunction?.(undefined);
                    }, true);
                case UserAction.POST_LOAD:
                    return ValidateLoadPosting(() => {
                        mandateUserVerificationCallBackFunction?.();
                        setMandateUserVerificationActionType?.("");
                        setMandateUserVerificationActionTriggered?.(false);
                        setMandateUserVerificationCallBackFunction?.(undefined);
                    }, true);
                case UserAction.LORRY_BID:
                    return ValidateLorryBidding(() => {
                        mandateUserVerificationCallBackFunction?.();
                        setMandateUserVerificationActionType?.("");
                        setMandateUserVerificationActionTriggered?.(false);
                        setMandateUserVerificationCallBackFunction?.(undefined);
                    }, true);
                case UserAction.POST_LORRY:
                    return ValidateLorryPosting(() => {
                        mandateUserVerificationCallBackFunction?.();
                        setMandateUserVerificationActionType?.("");
                        setMandateUserVerificationActionTriggered?.(false);
                        setMandateUserVerificationCallBackFunction?.(undefined);
                    }, true);

                default:
                    return;
            }
    }, [companyId, isPhoneVerified, mandateUserVerificationActionType, mandateUserVerificationActionTriggered]);

    useEffect(() => {
        isPhoneVerified && moEngageUserAttributeTrack("user_type", WebsiteUserType.CLIENT);
    }, [isPhoneVerified]);

    useEffect(() => {
        const ENV = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_REACT_APP_ENV;
        const downloadAppTestCampaign =
            ENV === "production" ? AB_TEST_CAMPAIGN_IDS["downloadAppProd"] : AB_TEST_CAMPAIGN_IDS["downloadApp"];
        if (
            abTestVariations[downloadAppTestCampaign] == 3 &&
            !isLoginModalOpen &&
            serviceType &&
            !(companyId && isPhoneVerified)
        ) {
            router?.push({ hash: "#Login" });
        }
    }, [serviceType, isPhoneVerified, abTestVariations, companyId]);

    return (
        <>
            <LoginModalGlobalWrapper />
            {showMandateKycPopup && <AadhaarAndPanVerification />}
            {showGstVerificationPopup && <GSTVerification />}
            {showUpgradeMembershipPlanPopup && <UpgradeMembershipPlanModal />}
            {/* {showLangMenu && <ChangeLanguage />} */}
            {showBankAddPopup && (
                <AddBankPage
                    onSuccess={onSuccessAddBankCallback}
                    isOpen={showBankAddPopup}
                    onToggle={handleBankPopupToggle}
                />
            )}
            {showAppRedirectionPopup && <AppRedirectionPopup />}

            {/* This is done only for marketplace, for other places like loadCard, lorryCard, Reactivation - load/lorry etc we need to update the context props */}
            {showShareModalPopup?.isVisible && (
                <ShareModal
                    isOpen={showShareModalPopup.isVisible}
                    modalTitle={`Share ${showShareModalPopup.mktType} details`}
                    toggle={() => setShowShareModalPopup?.({ isVisible: false, mktType: "load", data: undefined })}
                    data={showShareModalPopup.data}
                    mktType={`${showShareModalPopup.mktType}`}
                />
            )}
            {/* {showAppMandatePopup && !closeAppMandatePopup && <AppMandatePopup onCloseBtnClick={onCloseBtnClick} />} */}
            {showSignUpMandatePopup && !closeAppMandatePopup && serviceType && (
                <SignupMandatePopup onCloseBtnClick={onCloseBtnClick} />
            )}

            {serviceRequestType && (
                <ServiceRequestModal
                    onClose={() => {
                        router.replace({ ...router, hash: undefined });
                        setServiceRequestType?.(undefined);
                    }}
                />
            )}

            {showClearDuePopup && <ClearDueModal />}
        </>
    );
};

export default GlobalComponentsWrapper;
