const queryNames = {
    premiumTripListing: "premium-trip-listing",
    changeLorryStatus: "change-lorry-status",
    tripTrackListing: "trip-track-listing",
    changeDriverDetails: "change-driver-details",
    tripDocs: "trip-docs",
    postTripDocs: "post-trip-docs",
    myLoadBids: "my-load-bids",
    myLorryBids: "my-lorry-bids",
    uploadImage: "upload-image",
    UserLorriesForUpdatingDriverDetailsKam: "user-lorries-for-updating-driver-details-kam",
    userReports: "user-reports",
    getCompanyPostingAndBiddingLimit: "get-company-posting-and-bidding-limit",
    getLoadMarketV2: "getLoadMarketV2",
    getLoadMarketInfiniteQueryV2: "getLoadMarketInfiniteQueryV2",
    getCompanyDetails: "get-company-details",
    getCompanyInfo: "get-company-info",
    getLatAndLongBasedOnPlaceId: "getLatAndLongBasedOnPlaceId",
    deleteBankAccount: "deleteBankAccount",
    getLorryMarketV2: "getLorryMarketV2",
    getLorryMarketInfiniteQueryV2: "getLorryMarketInfiniteQueryV2",
    getShortLink: "getShortLink",
    getLiveLoadsByLorryIdInfiniteQueryV2: "getLiveLoadsByLorryIdInfiniteQueryV2",
    getLiveLorriesByLoadIdInfiniteQueryV2: "getLiveLorriesByLoadIdInfiniteQueryV2",

    bid: {
        postLoadBid: "postLoadBid",
        bidInfoUpdate: "bidInfoUpdate"
    },
    bulkTag: "bulkTag",
    bulkUnTag: "bulkUnTag",
    createVasTicket: "createVasTicket",
    trueCallerUserDetails: "trueCallerUserDetails",
    updateReportDisposition: "updateReportDisposition",
    getLorryCurrentTripLocation: "getLorryCurrentTripLocation",
    getLorryLocationHistory: "getLorryLocationHistory",
    getReverseGeoPoint: "getReverseGeoPoint",
    getLocationDetails: "getLocationDetails",
    updateLorryRoute: "updateLorryRoute",
    updateDriverDetails: "updateDriverDetails",
    attachDriverDetails: "attachDriverDetails",
    enableSimTracking: "enableSimTracking",
    removeDriverDetails: "removeDriverDetails",
    updateLoadDetails: "updateLoadDetails"
};

export default queryNames;
